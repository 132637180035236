import styled from "@emotion/styled/macro";
import { Button } from "../../../styled";
import { css } from "@emotion/react";
import { xxlTheme } from "../../../styles/xxl-theme";
import { MQ, typographyToCss, xxlScrollbars } from "../../../styles/helpers";
import { CloseIconSlim } from "@xxl/icons";
import {
  CONTAINER_OFFSET_DESKTOP,
  CONTAINER_OFFSET_MOBILE,
  FOOTER_SHADOW,
  HEADER_SHADOW,
} from "./constants";

const { colors, iconSize, typography, spaces } = xxlTheme;

export const FilterDrawerTopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.xxlWhite};
`;

export const FilterDrawerWrapper = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${colors.xxlWhite};
  color: ${colors.xxlWebBlack};
  justify-content: space-between;
  overflow: hidden;

  ${MQ("tablet")} {
    width: 390px;
  }
`;

export const FilterDrawerHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${spaces.large};
  background-color: var(--color-xxl-white);
  box-shadow: ${HEADER_SHADOW};
`;

export const FilterDrawerHeadline = styled.span`
  flex: 1;
  margin: 0 0 0 ${spaces.mini};
  ${typographyToCss(typography.bigBold)};
  letter-spacing: ${spaces.letterSpaceNormal};
  text-transform: capitalize;

  ${MQ("tablet")} {
    ${typographyToCss(typography.hugeBold)};
  }
`;

export const FilterHeading = styled.span`
  ${typographyToCss(typography.bigBold)}
  letter-spacing: ${spaces.letterSpaceNormal};
  text-transform: capitalize;
  margin: 0;
`;

export const FilterDrawerSection = styled.div`
  margin: 0 0 ${spaces.large} 0;

  &:first-of-type {
    padding-top: ${spaces.smallRegular};
  }
`;

export const FilterDrawerSortList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.micro};
  padding: ${spaces.regular} 0 0 0;

  &&& > div {
    border-bottom: 1px solid ${colors.xxlMediumGrey};
    a {
      padding: ${spaces.micro} ${spaces.micro} ${spaces.mini};
    }
  }
`;

export const FilterDrawerContainer = styled.div`
  height: calc(100dvh - ${CONTAINER_OFFSET_MOBILE}px);
  padding: 0 ${spaces.smallRegular};
  margin: ${spaces.smallRegular} ${spaces.smallRegular} ${spaces.smallRegular} 0;
  overflow-y: auto;
  ${xxlScrollbars(spaces.micro)};

  & div.search-list__no-result-wrapper {
    margin: ${spaces.regular} 0 0 0;

    .search-list__no-result-headline {
      display: none;
    }
    .search-list__no-result-message {
      padding: 0;
    }
  }

  ${MQ("tablet")} {
    height: calc(100dvh - ${CONTAINER_OFFSET_DESKTOP}px);
  }
`;

export const FilterDrawerFooter = styled.footer`
  position: absolute;
  bottom: 0;
  z-index: 1;
  display: flex;
  gap: ${spaces.mini};
  box-sizing: border-box;
  width: 100%;
  padding: ${spaces.smallRegular};
  background-color: var(--color-xxl-white);
  box-shadow: ${FOOTER_SHADOW};
`;

const ButtonBase = css`
  border: 0;
  text-transform: initial;
  ${typography.mediumRegular}
  letter-spacing: ${spaces.letterSpaceNormal};
  padding: ${spaces.regular} ${spaces.large};
  justify-content: center;
  align-items: center;
  white-space: nowrap;
`;

export const ShowProductsButton = styled(Button)`
  ${ButtonBase}
  background: ${colors.xxlBlack};
  color: ${colors.xxlWhite};
`;

export const ClearButton = styled(Button)`
  ${ButtonBase}
  background: ${colors.xxlWhite};
  border: 1px solid ${colors.xxlMediumGrey};
`;

export const CloseIconStyled = styled(CloseIconSlim)`
  width: ${iconSize.small};
  height: ${iconSize.small};
  margin-top: ${spaces.line};
  font-size: ${iconSize.small};
  cursor: pointer;
`;
